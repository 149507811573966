import { Components, Options, Splide } from '@splidejs/splide';

export function AdjustableHeight(Splide: Splide, Components: Components, options: Options) {
    // Useful elements
    const track = Components.Elements.track;
    const list = Components.Elements.list;

    // Custom options or using defaults
    const defaults = {
        whileSliding: true,
        speed: '0.4s',
    };

    let settings = defaults;
    const custom = options.adjustableHeight;

    if (custom) {
        settings.whileSliding = custom.whileSliding ?? defaults.whileSliding;
        settings.speed = custom.speed ?? defaults.speed;
    }

    function mount() {
        const eventType = settings.whileSliding
            ? 'intersection:in move active resize'
            : 'intersection:in active resized';
        Splide.on(eventType, adjustHeight);
    }

    function adjustHeight() {
        // When "whileSliding" is true it means altering the track element, when false means altering the list
        let element = settings.whileSliding ? track : list;
        let slideHeight = Components.Slides.getAt(Splide.index)?.slide.offsetHeight;

        // If changing track height, add additional padding on the track element to the total height
        let trackStyle = window.getComputedStyle(track);
        let trackPadding = parseInt(trackStyle.paddingTop) + parseInt(trackStyle.paddingBottom);
        let totalHeight = settings.whileSliding ? slideHeight! + trackPadding : slideHeight;

        // Let flex items have individual heights
        list.style.alignItems = 'flex-start';

        // Set transition and height
        element.style.transition = 'height ' + settings.speed;
        element.style.height = totalHeight + 'px';
    }

    return {
        mount,
    };
}
