export class animations {
    constructor() {
        this.counter();
    }

    counter(): void {
        const _reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

        if (!_reducedMotion || _reducedMotion.matches) {
            return;
        }

        const _counterAnim = (element: HTMLElement, start: number = 0, end: number | null, duration: number = 1000) => {
            let startTimestamp: number | null = null;

            const step = (timestamp: number) => {
                if (!startTimestamp) {
                    startTimestamp = timestamp;
                }

                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                element.innerText = '' + Math.floor(progress * (end ?? 0 - start) + start);

                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        };

        const _elements = document.querySelectorAll('[data-count-wrapper]');

        const _countObserver = new IntersectionObserver(function (_items) {
            _items.forEach((_item) => {
                const _countElem = _item.target.querySelectorAll('[data-count]') as NodeList;

                Array.from(_countElem as NodeListOf<HTMLElement>).forEach((_elem: HTMLElement) => {
                    const _targetElement = _elem.querySelector('.facts-entry__value') as HTMLElement;
                    let _end = 0;

                    if (_elem.dataset.count) {
                        _end = parseInt(_elem.dataset.count);
                    }

                    if (_targetElement && _item.isIntersecting) {
                        _targetElement.style.removeProperty('width');
                        _targetElement.style.width = _targetElement.offsetWidth + 'px';
                        _counterAnim(_targetElement, 0, _end);
                    }
                });
            });
        });

        const setFactsValueWidth = () => {
            Array.from(_elements as NodeListOf<HTMLElement>).forEach((_element: HTMLElement) => {
                const _targetElements = _element.querySelectorAll('.facts-entry__value') as NodeListOf<HTMLElement>;

                Array.from(_targetElements as NodeListOf<HTMLElement>).forEach((_targetElement: HTMLElement) => {
                    _targetElement.style.removeProperty('width');
                    const targetElementWidth = _targetElement.offsetWidth;
                    _targetElement.style.width = targetElementWidth + 'px';
                });
            });
        };

        Array.from(_elements as NodeListOf<HTMLElement>).forEach((_element: HTMLElement) => {
            _countObserver.observe(_element);
        });

        window.onresize = () => {
            _countObserver.disconnect();
            setFactsValueWidth();
        };
    }
}
