import Splide, { PaginationData } from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';
import { AdjustableHeight } from '../lib/SplideAdjustableHeight';

export class sliders {
    constructor() {
        this.initSplideSingleSliders();
        this.initHeroSlider();
        this.initQuotesSlider();
        this.initNativeSliders();
    }

    // slider for slides in text media for example. single image slides
    initSplideSingleSliders() {
        // splide slider
        // https://splidejs.com/guides/options/
        Array.from(document.getElementsByClassName('splide-single') as HTMLCollectionOf<HTMLElement>).forEach(
            (item) => {
                const splide = new Splide(item, {
                    type: 'loop',
                });

                splide.on('pagination:mounted', (data) => this.addSpanToPaginationItem(data));

                splide.on('pagination:updated', (data, prev, curr) => {
                    if (data) {
                        prev.li.classList.remove('is-active');
                        curr.li.classList.add('is-active');
                    }
                });

                splide.on('updated', function () {
                    let refreshed = false;

                    if (window.innerWidth > 767 && !refreshed) {
                        splide.refresh();
                        refreshed = true;
                    }
                });

                this.removeAttributes(splide);

                splide.mount();
            }
        );
    }

    initHeroSlider() {
        if (document.getElementsByClassName('hero-slider').length > 0) {
            const heroSlider = new Splide('#hero-slider', {
                type: 'loop',
                autoplay: true,
                breakpoints: {
                    575: {
                        autoplay: false,
                    },
                },
            });

            heroSlider.on('pagination:mounted', (data) => this.addSpanToPaginationItem(data));
            this.removeAttributes(heroSlider);

            heroSlider.mount();
        }
    }

    initQuotesSlider() {
        if (document.getElementsByClassName('quotes-slider').length > 0) {
            const quoteSlider = new Splide('#quotes-slider', {
                type: 'loop',
                autoplay: 'pause',
                intersection: {
                    inView: {
                        autoplay: true,
                    },
                    outView: {
                        autoplay: false,
                    },
                    threshold: 0.25,
                },
                breakpoints: {
                    768: {
                        autoplay: false,
                    },
                },
            });

            quoteSlider.on('pagination:mounted', (data) => this.addSpanToPaginationItem(data));
            quoteSlider.on('intersection:in', () => {
                const currentIndex = quoteSlider.index;
                const prevIndex = currentIndex === 0 ? quoteSlider.length - 1 : currentIndex - 1;
                const prevSlide = quoteSlider.Components.Elements.slides[prevIndex];

                prevSlide.querySelector('img')?.removeAttribute('loading');
            });

            this.removeAttributes(quoteSlider);
            quoteSlider.mount({ AdjustableHeight, Intersection });
        }
    }

    addSpanToPaginationItem(data: PaginationData) {
        if (data.items.length > 5) {
            data.list.classList.add('splide__pagination-numbers');
        }

        data.items.forEach((item) => {
            // @TODO: we might translate the x / x string properly.
            item.button.innerHTML =
                '<span class="splide__pagination__page--indicator"><span class="visually-hidden">' +
                (item.page + 1) +
                ' / ' +
                data.items.length +
                '</span></span>';
        });
    }

    initNativeSliders() {
        const nativeSliders = document.querySelectorAll('.slider-wrapper');

        nativeSliders.forEach((slider) => {
            const sliderTrack = slider.querySelector('.slider-track');
            const sliderTrackInner = sliderTrack!.querySelector('.slider-track__inner');
            const sliderControlPrev = slider.querySelector('.slider-control__prev');
            const sliderControlNext = slider.querySelector('.slider-control__next');
            const sliderDots = slider.querySelector('.slider-dots');
            const sliderDotsItems = sliderDots?.querySelectorAll('.slider-dots__item button');
            const sliderItems = slider.querySelectorAll('.slider-item');
            const sliderTrackInnerStyle = window.getComputedStyle(sliderTrackInner!);
            const sliderTrackInnerGap = sliderTrackInnerStyle.getPropertyValue('gap');
            const sliderItemWidth = sliderItems[0]?.clientWidth! + parseInt(sliderTrackInnerGap);

            if (sliderControlPrev && sliderControlNext && sliderDots) {
                window.addEventListener('resize', () => {
                    if (sliderTrackInner!.scrollWidth > sliderTrackInner!.clientWidth) {
                        sliderControlPrev?.classList.remove('hidden');
                        sliderControlNext?.classList.remove('hidden');
                        sliderDots?.classList.remove('hidden');
                    } else {
                        sliderControlPrev?.classList.add('hidden');
                        sliderControlNext?.classList.add('hidden');
                        sliderDots?.classList.add('hidden');
                    }
                });
                window.dispatchEvent(new Event('resize'));

                sliderControlNext.addEventListener('click', () => {
                    scroll('right');
                });

                sliderControlPrev.addEventListener('click', () => {
                    scroll('left');
                });

                sliderDotsItems?.forEach((item, index) => item.addEventListener('click', () => dotsNavigation(index)));

                const dotsNavigation = (index: number) => {
                    const activeDotIndex = getActiveDotIndex();
                    scroll(index > activeDotIndex ? 'right' : 'left', index - activeDotIndex);
                };

                const updateDots = (index: number) => {
                    const activeDotIndex = getActiveDotIndex();

                    sliderDotsItems![activeDotIndex].classList.remove('active');

                    if (index <= sliderDotsItems!.length - 1 && index >= 0) {
                        sliderDotsItems![index].classList.add('active');
                    }

                    if (index < 0) {
                        sliderDotsItems![0].classList.add('active');
                    }

                    if (index > sliderDotsItems!.length - 1) {
                        sliderDotsItems![sliderDotsItems!.length - 1].classList.add('active');
                    }
                };

                const scroll = (direction: 'right' | 'left', items: number = 1) => {
                    if (direction === 'right') {
                        const maxScrollLeft = sliderTrack!.scrollWidth - sliderTrack!.clientWidth;
                        let newScrollLeft = sliderItemWidth * items;

                        if (sliderTrack!.scrollLeft + sliderItemWidth > maxScrollLeft) {
                            newScrollLeft = maxScrollLeft - sliderTrack!.scrollLeft;
                        }

                        sliderTrack?.scrollBy({ left: newScrollLeft, behavior: 'smooth' });
                    } else {
                        let newScrollLeft = sliderItemWidth * items;

                        if (sliderTrack!.scrollLeft - sliderItemWidth < 0) {
                            newScrollLeft = sliderTrack!.scrollLeft;
                        }

                        sliderTrack?.scrollBy({ left: Math.abs(newScrollLeft!) * -1, behavior: 'smooth' });
                    }
                };

                const getActiveDotIndex = (): number => {
                    const nodeArray = Array.from(sliderDotsItems!);
                    return nodeArray.findIndex((element) => element.classList.contains('active'));
                };

                sliderTrack?.addEventListener('scroll', () => {
                    slider.classList.add('is-scrolling');

                    const isFullyScrolled =
                        sliderTrack!.scrollWidth - Math.round(sliderTrack!.scrollLeft) === sliderTrack?.clientWidth;
                    const isAtStart = sliderTrack?.scrollLeft === 0;

                    if (isAtStart) {
                        sliderControlPrev?.setAttribute('disabled', 'disabled');
                        sliderControlNext?.removeAttribute('disabled');
                    }

                    if (isFullyScrolled) {
                        sliderControlNext?.setAttribute('disabled', 'disabled');
                        sliderControlPrev?.removeAttribute('disabled');
                    }

                    if (!isAtStart && !isFullyScrolled) {
                        sliderControlNext?.removeAttribute('disabled');
                        sliderControlPrev?.removeAttribute('disabled');
                    }
                });

                sliderTrack?.addEventListener('scrollend', () => {
                    slider.classList.remove('is-scrolling');
                    updateDots(getCenteredItem());
                });

                const getCenteredItem = (): number => {
                    const containerRect = sliderTrack?.getBoundingClientRect();
                    let centeredItemIndex = null;

                    sliderItems.forEach((item, index) => {
                        const itemRect = item.getBoundingClientRect();
                        const itemCenter = (itemRect.left + itemRect.right) / 2;
                        const containerCenter = (containerRect!.left + containerRect!.right) / 2;

                        if (Math.abs(itemCenter - containerCenter) < itemRect.width / 2) {
                            centeredItemIndex = index;
                        }
                    });

                    return centeredItemIndex ? centeredItemIndex - 1 : -1;
                };
            }
        });
    }

    removeAttributes(slider: any) {
        slider.on('mounted', () => {
            document.querySelectorAll('.splide__slide').forEach((slide) => {
                slide.removeAttribute('role');
                slide.removeAttribute('aria-roledescription');
            });
        });
    }
}
