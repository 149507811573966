export class Contrast {
    checkContrast(foregroundSelector: string, backgroundSelector: string) {
        const foreground = document.querySelector(foregroundSelector) as HTMLElement;
        const background = document.querySelector(backgroundSelector) as HTMLImageElement;

        if (foreground && background) {
            const avgColor = this.getAverageColor(background);
            const fgColor = window.getComputedStyle(foreground).color;
            const contrastRatio = this.getContrastRatio(fgColor, avgColor);

            if (contrastRatio < 3.5) {
                console.log('possible low contrast: ' + contrastRatio);
                background?.classList.add('low-contrast');
            }
        }
    }

    getContrastRatio(fgColor: string, bgColor: string): number {
        const fgLuminance = this.getLuminance(fgColor);
        const bgLuminance = this.getLuminance(bgColor);

        return (Math.max(fgLuminance, bgLuminance) + 0.05) / (Math.min(fgLuminance, bgLuminance) + 0.05);
    }

    getLuminance(color: string): number {
        let r = 0,
            g = 0,
            b = 0;

        if (color.startsWith('#')) {
            // Hex color
            r = parseInt(color.slice(1, 3), 16) / 255;
            g = parseInt(color.slice(3, 5), 16) / 255;
            b = parseInt(color.slice(5, 7), 16) / 255;
        } else if (color.startsWith('rgb')) {
            const rgbValues = color.match(/\d+/g);
            if (rgbValues) {
                r = parseInt(rgbValues[0]) / 255;
                g = parseInt(rgbValues[1]) / 255;
                b = parseInt(rgbValues[2]) / 255;
            }
        }

        // Calculate relative luminance for sRGB
        r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
        g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
        b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

    getAverageColor(img: HTMLImageElement) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const pictureElement = img?.parentElement as HTMLPictureElement;
        const rect = pictureElement?.parentElement?.getBoundingClientRect();

        canvas.width = rect?.width ?? 0;
        canvas.height = rect?.height ?? 0;
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData?.data ?? [];
        let r = 0,
            g = 0,
            b = 0;
        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }

        const count = data.length / 4;
        r = Math.round(r / count);
        g = Math.round(g / count);
        b = Math.round(b / count);

        return `rgb(${r}, ${g}, ${b})`;
    }
}
