export class formhandler {
    private readonly selector: string;

    constructor(selector: string) {
        this.selector = selector;
    }

    formHandler(): void {
        const forms: NodeListOf<HTMLFormElement> = document.querySelectorAll(this.selector);

        forms?.forEach((form) => {
            const invalidInput = form.querySelector('.is-invalid');
            const dynamicLists = form.querySelectorAll('.dynamic-list');
            const InputWithDependency: NodeListOf<HTMLInputElement> = form.querySelectorAll(
                '.has-dependency[type="checkbox"], .has-dependency[data-toggle]'
            );
            const btnBack = form.querySelector('.btn-back');

            dynamicLists.forEach((dynamicList) => {
                const listItems: NodeListOf<HTMLDivElement> = dynamicList.querySelectorAll('.dynamic-list__item');
                const divArray = Array.from(listItems);
                const controlBtns: NodeListOf<HTMLButtonElement> = dynamicList.querySelectorAll('.btn');
                const listItemsWithValues = getFilledItems(listItems);

                listItemsWithValues.forEach((listItemsWithValue) => {
                    toggleElem(listItemsWithValue, true);
                });

                controlBtns.forEach((controlBtn) => {
                    controlBtn.addEventListener('click', (event: Event) => {
                        const btn = event.target as HTMLButtonElement;
                        const firstHiddenListItem = divArray.find((div) => div.classList.contains('d-none')) || null;
                        const lastVisibleListItem = divArray.reduceRight<HTMLDivElement | null>((last, div) => {
                            return last || (div.classList.contains('d-none') ? null : div);
                        }, null);

                        if (btn.classList.contains('btn-add')) {
                            toggleElem(firstHiddenListItem as HTMLElement, true);
                            firstHiddenListItem?.querySelector('input')?.focus();
                        }

                        if (btn.classList.contains('btn-remove')) {
                            toggleElem(lastVisibleListItem as HTMLElement, false);
                        }

                        const visibleDivs = divArray.filter((div) => !div.classList.contains('d-none'));
                        const invisibleDivs = divArray.filter((div) => div.classList.contains('d-none'));

                        btnHandler(visibleDivs.length, controlBtns);
                        clearNodeList(getInputsOfHiddenItems(invisibleDivs));
                    });

                    controlBtn.dispatchEvent(new Event('click'));
                });
            });

            InputWithDependency.forEach((input) => {
                if (input instanceof HTMLInputElement) {
                    const fieldset = document.getElementById(
                        input.getAttribute('id')!.replace('checkbox-add', 'fieldset')
                    );

                    if (fieldset) {
                        toggleElem(fieldset, input.checked);

                        input.addEventListener('change', function () {
                            toggleElem(fieldset, this.checked);
                        });
                    }
                }

                if (input.getAttribute('data-toggle')) {
                    const dataToggleValue = input.dataset.toggle;
                    const data = transformToggleData(dataToggleValue);
                    const radios = input.querySelectorAll<HTMLInputElement>('input[type="radio"]');

                    radios.forEach((radio) => {
                        radio.addEventListener('change', () => {
                            const selectedRadio = input.querySelector<HTMLInputElement>('input[type="radio"]:checked');
                            const selectedRadioValue = selectedRadio?.value;

                            if (data!.hasOwnProperty(selectedRadioValue!)) {
                                data![selectedRadio!.value]?.forEach((elem) => {
                                    toggleElem(elem as HTMLElement, true);
                                });
                            } else {
                                if (data) {
                                    Object.values(data).forEach((value) => {
                                        value.forEach((elem) => {
                                            toggleElem(elem as HTMLElement, false);
                                        });
                                    });
                                }
                            }
                        });

                        radio.dispatchEvent(new Event('change'));
                    });
                }
            });

            btnBack?.addEventListener('click', () => {
                form.submit();
            });

            invalidInput?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });

        function clearNodeList(fields: NodeListOf<HTMLInputElement> | Element[]) {
            fields.forEach((field) => {
                // @ts-ignore
                switch (field.type) {
                    case 'radio':
                    case 'checkbox':
                        if ('checked' in field) {
                            field.checked = false;
                        }
                        break;

                    default:
                        if ('value' in field) {
                            field.value = '';
                        }
                        break;
                }

                field.removeAttribute('required');
            });
        }

        function toggleElem(elem: HTMLElement, checked: boolean) {
            if (!elem) {
                return;
            }

            if (checked) {
                const elems = elem.querySelectorAll('.is-required');
                const hasFieldToPrefill = elem.querySelector('[data-prefill]');
                elem?.classList.remove('d-none');
                setRequiredFields(elems);

                if (hasFieldToPrefill) {
                    prefillRadio(hasFieldToPrefill);
                }
            } else {
                elem?.classList.add('d-none');
                clearNodeList(elem.querySelectorAll('input, select'));
            }
        }

        function setRequiredFields(fields: NodeListOf<Element>) {
            fields.forEach((field) => {
                field.setAttribute('required', 'required');
            });
        }

        function btnHandler(length: number, buttons: NodeListOf<HTMLButtonElement>) {
            const addBtn = Array.from(buttons).find((button) => button.classList.contains('btn-add'));
            const removeBtn = Array.from(buttons).find((button) => button.classList.contains('btn-remove'));
            if (length === 1) {
                addBtn?.classList.remove('d-none');
                removeBtn?.classList.add('d-none');
            }

            if (length > 1 && length < 10) {
                addBtn?.classList.remove('d-none');
                removeBtn?.classList.remove('d-none');
            }

            if (length === 10) {
                addBtn?.classList.add('d-none');
                removeBtn?.classList.remove('d-none');
            }
        }

        function getInputsOfHiddenItems(items: HTMLDivElement[]): Element[] {
            const fields: HTMLInputElement[] = [];

            items.forEach((div) => {
                const requiredFields: NodeListOf<HTMLInputElement> = div.querySelectorAll('.is-required');
                fields.push(...requiredFields);
            });

            return fields;
        }

        function getFilledItems(divs: NodeListOf<HTMLDivElement>): HTMLDivElement[] {
            return Array.from(divs).filter((div) => {
                const textInput = div.querySelector('input[type="text"]') as HTMLInputElement | null;
                const checkedRadioInput = div.querySelector('input[type="radio"]:checked') as HTMLInputElement | null;

                return textInput?.value.trim() !== '' && checkedRadioInput;
            });
        }

        function transformToggleData(string: string | undefined) {
            if (!string) {
                return;
            }

            const parsedData: Record<string, string> = JSON.parse(string);
            const result: Record<string, HTMLElement[]> = {};

            for (const [key, value] of Object.entries(parsedData)) {
                const selectors = value.split(', ').map((selector) => selector.trim());
                result[key] = selectors
                    .map((selector) => document.querySelector('.' + selector))
                    .filter((el): el is HTMLElement => el !== null);
            }

            return result;
        }

        function prefillRadio(element: Element) {
            const valueToToggle = element.getAttribute('data-prefill');
            const radioToCheck = element.querySelector<HTMLInputElement>(`input[type=radio][value="${valueToToggle}"]`);

            if (radioToCheck) {
                radioToCheck.checked = true;
            }
        }
    }
}
