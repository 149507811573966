import { AbstractNav, NavOptions } from './abstract/AbstractNav';

export class SubNav extends AbstractNav {
    private static readonly SUBNAV_BACK_BUTTON_CLASS = 'main-nav__back-button';
    private static readonly SUBNAV_LINK_LIST_WRAPPER_CLASS = 'main-nav__subnav-link-list';
    private static readonly SUBNAV_LEVEL_CLASS_PREFIX = 'main-nav__subnav--level-';
    private static readonly SUBNAV_SIDEBAR_CLASS = 'main-nav__sidebar';
    private static readonly SUBNAV_CSS_VISIBLE_SIDEBAR_HEIGHT = '--subnav-visible-sidebar';
    private static readonly SUBNAV_CSS_ITEMS_HEIGHT = '--subnav-items-height';
    private static readonly SUBNAV_CSS_TOGGLEABLE_SIDEBAR_HEIGHT = '--subnav-toggleable-sidebar';

    private static instances: SubNav[] = [];

    private minHeightAnimationFrameId: number | null = null;
    private sidebarElement: HTMLElement | null;
    private linkListWrapper: HTMLElement | null;
    private headerElement: HTMLElement | null;

    constructor(options: NavOptions) {
        super(options, 'sub-nav');

        AbstractNav.IS_DESKTOP_NAV_MQL.addEventListener('change', () => this.onMediaQueryChange());
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            this.navElement.hidden = true;
            this.navElement.tabIndex = -1;
        }

        if (this.level > 0) {
            this.toggleElements
                .filter((toggle) => !toggle.classList.contains(SubNav.SUBNAV_BACK_BUTTON_CLASS))
                .map((toggle) => toggle.parentElement as HTMLElement)
                .map((toggle) => toggle.parentElement as HTMLElement)
                .forEach((parentElement) => {
                    parentElement.addEventListener('mouseenter', (event) => this.onParentItemInteractionStart(event));
                    parentElement.addEventListener('mouseenter', () => this.setActiveClassToItem());
                    parentElement.addEventListener('mouseleave', (event) => this.onParentItemInteractionEnd(event));
                    parentElement.addEventListener('mouseleave', () => this.unsetActiveClassToItem());
                    parentElement.addEventListener('focusin', (event) => this.onParentItemInteractionStart(event));
                    parentElement.addEventListener('focusout', (event) => this.onParentItemInteractionEnd(event));
                });
        }

        this.sidebarElement = this.element.parentElement?.querySelector(`.${SubNav.SUBNAV_SIDEBAR_CLASS}`) || null;
        this.linkListWrapper = this.element.querySelector(`.${SubNav.SUBNAV_LINK_LIST_WRAPPER_CLASS}`) || null;
        this.headerElement = document.getElementById('header') || null;
    }

    get level(): number {
        const levelClass = Array.from(this.element.classList.values()).find((className) =>
            className.startsWith(SubNav.SUBNAV_LEVEL_CLASS_PREFIX)
        );
        if (!levelClass) {
            throw new Error('Subnav does not have level-class');
        }
        return parseInt(levelClass.replace(SubNav.SUBNAV_LEVEL_CLASS_PREFIX, ''));
    }

    get sidebar(): HTMLElement | null {
        return this.sidebarElement;
    }

    get firstLevelSubNav(): HTMLElement {
        let firstLevelSubNavElement: HTMLElement | null = this.element;
        while (!firstLevelSubNavElement.classList.contains(SubNav.SUBNAV_LEVEL_CLASS_PREFIX + '0')) {
            if (!firstLevelSubNavElement.parentElement) {
                throw new Error('Element does not have first level subnav');
            }
            firstLevelSubNavElement = firstLevelSubNavElement.parentElement;
        }
        return firstLevelSubNavElement;
    }

    private get alwaysVisibleSidebar(): HTMLElement | null {
        return (
            Array.from(this.navElement.querySelectorAll<HTMLElement>('.' + SubNav.SUBNAV_SIDEBAR_CLASS)).find(
                (sidebar) => !!sidebar.offsetParent
            ) || null
        );
    }

    private onMediaQueryChange() {
        this.navElement.hidden = true;
        this.setToggleElementExpandedState();

        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            this.navElement.tabIndex = -1;
        } else {
            this.navElement.style.setProperty(SubNav.SUBNAV_CSS_ITEMS_HEIGHT, '');
            this.navElement.style.setProperty(SubNav.SUBNAV_CSS_TOGGLEABLE_SIDEBAR_HEIGHT, '');
            this.navElement.removeAttribute('tabindex');
        }
    }

    private onParentItemInteractionStart(event: MouseEvent | FocusEvent) {
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            this.firstLevelSubNav.style.minHeight = '';
            this.minHeightAnimationFrameId = requestAnimationFrame(() => {
                this.firstLevelSubNav.style.setProperty(
                    SubNav.SUBNAV_CSS_ITEMS_HEIGHT,
                    `${this.element.scrollHeight + 64}px`
                );
                this.firstLevelSubNav.style.setProperty(
                    SubNav.SUBNAV_CSS_TOGGLEABLE_SIDEBAR_HEIGHT,
                    `${this.sidebar?.scrollHeight || 0}px`
                );
                this.minHeightAnimationFrameId = null;
            });
            if (event instanceof MouseEvent) {
                this.show();
            }
        }
    }

    private onParentItemInteractionEnd(event: MouseEvent | FocusEvent) {
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            const isTargetChildOfCurrentNavigation =
                event.relatedTarget instanceof HTMLElement &&
                event.currentTarget instanceof HTMLElement &&
                event.currentTarget.contains(event.relatedTarget);
            if (!isTargetChildOfCurrentNavigation) {
                this.firstLevelSubNav.style.minHeight = '';
                if (event instanceof MouseEvent) {
                    this.hide();
                }
            }
        }
    }

    private setActiveClassToItem() {
        this.toggleElements
            .filter((toggle) => !toggle.classList.contains(SubNav.SUBNAV_BACK_BUTTON_CLASS))
            .map((toggle) => toggle.parentElement as HTMLElement)
            .forEach((parentElement) => {
                parentElement.classList.add('main-nav__link-wrapper--active');
            });
    }

    private unsetActiveClassToItem() {
        this.toggleElements
            .filter((toggle) => !toggle.classList.contains(SubNav.SUBNAV_BACK_BUTTON_CLASS))
            .map((toggle) => toggle.parentElement as HTMLElement)
            .forEach((parentElement) => {
                parentElement.classList.remove('main-nav__link-wrapper--active');
            });
    }

    private setOverflowScrollIfNecessary() {
        const windowHeight = window.innerHeight;
        const headerHeight = this.headerElement!.clientHeight;
        const style = getComputedStyle(this.navElement);
        const itemTopMargin = parseInt(style.marginTop);
        const navElementHeight = this.navElement.clientHeight + itemTopMargin;
        const maxAvailableHeight = windowHeight - headerHeight - itemTopMargin;

        this.navElement.style.removeProperty('height');
        this.linkListWrapper?.classList.remove('overflow-scroll');

        if (!AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            return;
        }

        if (maxAvailableHeight < navElementHeight) {
            this.navElement.style.height = maxAvailableHeight - 32 + 'px';
            this.linkListWrapper?.classList.add('overflow-scroll');
        }
    }

    public override show(event?: Event) {
        if (this.level === 0) {
            requestAnimationFrame(() => {
                this.navElement.style.setProperty(
                    SubNav.SUBNAV_CSS_VISIBLE_SIDEBAR_HEIGHT,
                    `${this.alwaysVisibleSidebar?.scrollHeight || 0}px`
                );
            });
        }
        return super.show(event);
    }

    public override hide(event?: Event, returnFocus?: boolean) {
        if (this.level === 0) {
            this.navElement.style.setProperty(SubNav.SUBNAV_CSS_VISIBLE_SIDEBAR_HEIGHT, '');
        }
        this.firstLevelSubNav.style.setProperty(SubNav.SUBNAV_CSS_ITEMS_HEIGHT, '');
        this.firstLevelSubNav.style.setProperty(SubNav.SUBNAV_CSS_TOGGLEABLE_SIDEBAR_HEIGHT, '');
        if (typeof this.minHeightAnimationFrameId === 'number') {
            cancelAnimationFrame(this.minHeightAnimationFrameId);
            this.minHeightAnimationFrameId = null;
        }
        return super.hide(event, returnFocus);
    }

    protected override setToggleElementExpandedState() {
        super.setToggleElementExpandedState();
        if (this.level === 1) {
            this.toggleElements.forEach((toggleElement) => {
                if (toggleElement.parentElement?.classList.contains('main-nav__link-wrapper')) {
                    toggleElement.parentElement.classList.toggle(
                        'main-nav__link-wrapper--active',
                        !this.navElement.hidden
                    );
                }
            });
        }
        if (this.level === 0) {
            this.setOverflowScrollIfNecessary();
        }
    }

    protected override initialFocusTarget() {
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            return this.navElement;
        }
        return super.initialFocusTarget();
    }

    static init(): SubNav[] {
        if (!this.instances.length) {
            Array.from(document.getElementsByClassName('main-nav__subnav')).forEach((subnav) => {
                if (!subnav.id) {
                    return;
                }
                this.instances.push(
                    new SubNav({
                        navElementId: subnav.id,
                        subnavSidebar: subnav.nextElementSibling as HTMLElement,
                    })
                );
            });
        }
        return this.instances;
    }
}
