declare var window: {
    tidioChatApi: any;
} & typeof globalThis;

/**
 * TidioChat Class integrates the Tidio Chat widget into a web page and handles user interactions.
 */
export class TidioChat {
    /**
     * Represents an HTML `<script>` element providing methods and properties
     * for manipulating script elements in the DOM.
     *
     * @type {HTMLScriptElement}
     */
    private readonly template: HTMLScriptElement;

    /**
     * Represents an HTML <script> element in the document.
     *
     * The `HTMLScriptElement` interface provides special properties and methods (beyond the regular
     * `HTMLElement` interface) for manipulating `<script>` elements.
     */
    private readonly script: HTMLScriptElement;

    /**
     * Represents a dialog element in the HTML document.
     * This variable is used to control and manipulate the dialog,
     * allowing it to be shown, hidden, and interacted with through
     * various methods and properties inherent to `HTMLDialogElement`.
     *
     * The dialog element can display content to the user and can be
     * customized with different styles, forms, and interactive elements.
     *
     * It is essential to ensure that the dialog element is properly
     * initialized and used within the DOM lifecycle to avoid issues
     * related to null references or manipulation errors.
     *
     * Dialog elements are particularly useful for modal interactions,
     * confirmations, and user-specific prompts that need attention or
     * immediate response.
     *
     * @type {HTMLDialogElement}
     */
    private readonly dialogElement: HTMLDialogElement;

    /**
     * The button element that triggers the opening of a dialog.
     *
     * This element is expected to be part of the DOM and connected
     * to a function or event listener that manages the display of a dialog modal or popup.
     *
     * @type {HTMLButtonElement}
     */
    private readonly dialogButtonOpen: HTMLButtonElement;

    /**
     * The dialogButtonClose variable represents a button element which, when
     * interacted with (e.g., clicked), typically triggers the closing of a dialog
     * box or modal window within a web application user interface. This button
     * is rendered as an HTMLButtonElement and is expected to be part of the dialog
     * structure, often identified by a specific CSS class or id designed to
     * signify its intent to close the dialog.
     */
    private readonly dialogButtonClose: HTMLButtonElement;

    /**
     * Represents an HTML button element intended to confirm an action or provide permission within a dialog interface.
     * This button can be utilized in modal dialogs, alert boxes, or any other UI component that requires user consent or allowance.
     *
     * @type {HTMLButtonElement}
     */
    private readonly dialogButtonAllow: HTMLButtonElement;

    /**
     * Represents the `<body>` element in the HTML document.
     * Provides access to the content of the body, including methods and properties to manipulate or access elements within it.
     * Can be used to dynamically change the content, appearance, and behavior of the web page's body.
     *
     * @type {HTMLBodyElement}
     */
    private readonly body: HTMLBodyElement;

    /**
     * Specifies the default timeout duration in milliseconds.
     * Used to determine how long the system should wait before timing out an operation.
     * Default value is set to 1000 milliseconds (1 second).
     *
     * @type {number}
     */
    private readonly timeout: number = 1000;

    /**
     * TidioChat constructor.
     *
     * Initializes the TidioChat instance by setting up the template, script element,
     * dialog elements and body. Executes the dialog method to set up event listeners
     * and dialogs.
     */
    constructor() {
        this.log('TidioChat constructor');

        this.template = document.querySelector('[data-supi-cookies="tidio"]') as HTMLScriptElement;
        this.script = <HTMLScriptElement>document.createElement('script');

        this.dialogElement = document.getElementById('consent-tidio') as HTMLDialogElement;
        this.dialogButtonOpen = document.getElementById('open-consent-tidio') as HTMLButtonElement;
        this.dialogButtonClose = document.getElementById('close-consent-tidio') as HTMLButtonElement;
        this.dialogButtonAllow = document.getElementById('allow-consent-tidio') as HTMLButtonElement;
        this.body = document.querySelector('body') as HTMLBodyElement;

        this.dialog();
    }

    /**
     * Initializes the TidioChat integration, setting up required event listeners and configurations.
     *
     * @return {void} No return value.
     */
    init(): void {
        this.log('TidioChat init');

        (window as any).initTidioChat = () => {
            this.script.className = 'supi-scripts';
            this.script.async = true;
            this.script.dataset.supiCookies = this.template.dataset.supiCookies;
            this.script.innerHTML = this.template.innerHTML;
            this.template.parentNode?.replaceChild(this.script, this.template);
        };

        // reload, when banner hides and tidio is activated or
        // the state is different from previous tidio selection
        window.addEventListener('bannerHide', ((e: CustomEvent) => {
            this.log('bannerHide');

            const activeState: string = sessionStorage.getItem('open-tidio') ?? 'n';
            const newState: string = e.detail['tidio-chat'] ?? 'n';

            if (newState !== activeState && activeState === 'y') {
                this.log('reload after bannerhide');

                window.location.reload();
            }
        }) as EventListener);

        window.addEventListener('serviceCallback', (() => {
            this.log('service callback');

            this.testTidio()
                .then(() => {
                    this.log('tidio is ready after service callback');

                    if (sessionStorage.getItem('open-tidio')) {
                        this.log('open tidio chat window');

                        window.tidioChatApi?.open();
                        sessionStorage.removeItem('open-tidio');
                    }

                    this.dialogButtonOpen?.classList.add('d-none');
                })
                .catch(() => {});
        }) as EventListener);
    }

    /**
     * Initializes and manages the TidioChat dialog interface. This includes handling
     * the open, close, and allow actions of the dialog, as well as additional behaviors
     * such as closing the dialog when clicked outside of it or when the Escape key is pressed.
     *
     * @return {void} Does not return any value.
     */
    private dialog(): void {
        this.log('TidioChat dialog');

        this.dialogButtonOpen?.addEventListener('click', () => {
            this.log('open dialog');
            this.dialogElement?.show();
            this.body?.classList.add('overflow-hidden');
        });

        this.dialogButtonClose?.addEventListener('click', () => {
            this.log('dismiss dialog');

            this.dialogElement?.close();
            this.body?.classList.remove('overflow-hidden');
        });

        this.dialogButtonAllow?.addEventListener('click', () => {
            this.log('allow chat in dialog');

            this.dialogElement?.close();
            this.body?.classList.remove('overflow-hidden');
            sessionStorage.setItem('open-tidio', 'y');
        });

        this.dialogElement?.addEventListener('click', (event) => {
            const rect = this.dialogElement.getBoundingClientRect();
            const isInDialog =
                event.clientX >= rect.left &&
                event.clientX <= rect.right &&
                event.clientY >= rect.top &&
                event.clientY <= rect.bottom;

            if (!isInDialog) {
                this.log('dismiss dialog outside clicked');

                this.dialogElement.close();
                this.body?.classList.remove('overflow-hidden');
            }
        });

        this.dialogElement?.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this.log('dismiss dialog via escape');
                this.dialogElement.close();
                this.body?.classList.remove('overflow-hidden');
            }
        });

        this.testTidio()
            .then(() => {
                this.log('tidio is ready in dialog');

                this.dialogButtonOpen?.remove();
                this.dialogElement?.remove();
            })
            .catch(() => {
                this.log('tidio is not ready in dialog');

                this.dialogButtonOpen?.classList.remove('d-none');
            });
    }

    /**
     * Checks if the Tidio chat API is loaded within a 1000ms timeframe.
     *
     * This method continuously checks for the presence of 'tidioChatApi' in
     * the window object at 30ms intervals until it finds it or the allotted
     * time (1000ms) has elapsed.
     *
     * @return {Promise<void>} A promise that resolves if the Tidio API
     * is loaded within the timeframe, and rejects if the API is not detected
     * within 1000ms.
     */
    private testTidio(): Promise<void> {
        const start = Date.now();

        const waitForTidio = (resolve: any, reject: any, timeout: number = this.timeout) => {
            if ('tidioChatApi' in window) {
                this.log('tidio is ready and loaded');

                window.tidioChatApi.on('ready', () => {
                    this.log('tidio is ready event');
                });

                window.tidioChatApi.on('close', () => {
                    this.log('chat close');
                });

                window.tidioChatApi.on('open', () => {
                    this.log('chat open');
                });

                resolve();
            } else if (Date.now() - start >= timeout) {
                this.log('tidio is still not loaded after ' + timeout + 'ms');

                reject();
            } else {
                setTimeout(waitForTidio.bind(this, resolve, reject), 30);
            }
        };

        return new Promise(waitForTidio);
    }

    /**
     * Logs a message to the console if the document body element has the 'develop' class.
     *
     * @param {string} message - The message to log to the console.
     * @return {void}
     */
    private log(message: string): void {
        const body = document.querySelector('body') as HTMLBodyElement;
        if (body?.classList.contains('develop')) {
            console.log(message);
        }
    }
}
