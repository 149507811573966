import { isDevelopmentEnvironment } from '../helper/isDevelopmentEnvironment';
import { isInIframe } from '../helper/isInIframe';

export class MobileLanguageSelect {
    private static instance?: MobileLanguageSelect;

    private readonly selectElement!: HTMLSelectElement;

    constructor() {
        const selectElement = document.querySelector<HTMLSelectElement>('.language-select--mobile select');
        if (!selectElement) {
            // we don't need any nav in pages opened in iframe
            // e.g. opening the contact form.
            if (isInIframe()) {
                return;
            }

            if (isDevelopmentEnvironment()) {
                console.error('No mobile language select element found');
            }

            return;
        }
        this.selectElement = selectElement;
        this.selectElement.addEventListener('change', () => this.onLanguageChange());
    }

    private onLanguageChange(): void {
        const [selectedOption] = this.selectElement.selectedOptions;
        const { link } = selectedOption.dataset;
        if (link) {
            window.location.href = link;
        }
    }

    static init(): MobileLanguageSelect {
        return this.instance || (this.instance = new MobileLanguageSelect());
    }
}
